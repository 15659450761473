.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  position: relative;
  overflow: auto; /* Allow scrolling */
}

.background-container {
  position: fixed; /* Fix the background in place */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  z-index: -1; /* Ensure it is behind other content */
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1; /* Ensure content is above the background */
}

.icons-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Add some spacing from the logo */
  margin-bottom: 20px;
}

.icon {
  margin: 0 10px; /* Add some spacing between icons */
}

.icon-image {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
}

.icon-image:hover {
  transform: scale(1.2); /* Scale up the icon on hover */
}

.contract-address {
  margin-top: 20px; /* Add some spacing from the top */
  position: relative; /* Change to relative for normal flow */
  color: #00f;
  font-size: 1.5em;
  text-shadow: 0 0 5px #00f, 0 0 10px #00f, 0 0 15px #00f, 0 0 20px #00f, 0 0 25px #00f, 0 0 30px #00f, 0 0 35px #00f;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.image3-container {
  margin-top: 20px; /* Add some spacing from the contract address */
  width: 100%;
  z-index: 1; /* Ensure image3 is above the background */
}

.image3 {
  width: 100%;
  border-radius: 20px;
  height: auto;
}

.App-footer {
  font-size: 1em;
  color: #00f;
  text-shadow: 0 0 5px #00f, 0 0 10px rgba(0, 0, 255, 0), 0 0 15px #00f, 0 0 20px #00f, 0 0 25px #00f, 0 0 30px #00f, 0 0 35px #00f;
  margin-bottom: 20px;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  background-color: rgba(255, 255, 255, 0); /* Make the footer background transparent */
  padding: 10px;
  border-radius: 5px;
  position: relative; /* Ensure the copied message is positioned relative to the footer */
}

.copied-message {
  position: absolute;
  top: -30px; /* Position the message above the footer */
  left: 50%;
  transform: translateX(-50%);
  background-color: #00f;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  text-shadow: none; /* Remove text shadow for better readability */
}

@media (max-width: 600px) {
  .App {
    height: auto; /* Allow height to adjust based on content */
  }

  .background-container {
    height: 100%; /* Adjust height to cover the content */
  }

  .content {
    width: 100%; /* Ensure content takes full width */
    padding: 10px; /* Add some padding */
  }

  .icons-bar {
    flex-wrap: wrap; /* Allow icons to wrap to the next line */
    margin-top: 10px; /* Adjust spacing */
    margin-bottom: 10px; /* Adjust spacing */
  }

  .icon {
    margin: 5px; /* Adjust spacing between icons */
  }

  .icon-image {
    width: 50px; /* Adjust size for mobile */
    height: 50px; /* Adjust size for mobile */
  }

  .contract-address {
    font-size: 1em; /* Adjust font size for mobile */
    padding: 5px; /* Adjust padding */
  }

  .image3 {
    width: 100%; /* Ensure image takes full width */
    border-radius: 10px; /* Adjust border radius */
  }

  .App-footer {
    font-size: 0.8em; /* Adjust font size for mobile */
    padding: 5px; /* Adjust padding */
  }

  .copied-message {
    font-size: 0.7em; /* Adjust font size for mobile */
    padding: 5px; /* Adjust padding */
  }
}